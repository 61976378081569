import * as React from 'react';
import { BackgroundImageProps } from '@wix/thunderbolt-components-native';
import BackgroundImage from '../../../BackgroundImage/viewer/BackgroundImage';

export type BackgroundDivOrImageProps = {
  backgroundImageProps?: BackgroundImageProps;
  bgClass: string;
};

const BackgroundDivOrImage: React.FC<BackgroundDivOrImageProps> = ({
  bgClass,
  backgroundImageProps,
}) => {
  return backgroundImageProps ? (
    <BackgroundImage {...backgroundImageProps} className={bgClass} />
  ) : (
    <div className={bgClass} />
  );
};

export default BackgroundDivOrImage;
