import * as React from 'react';
import { IBgImageStripProps } from '../../../BgImageStrip.types';
import BackgroundDivOrImage from '../../Common/BackgroundDivOrImage';
import style from './style.scss';

const DoubleBorderBgImageStrip: React.FC<IBgImageStripProps> = props => {
  const { id, skin, backgroundImage } = props;
  return (
    <div id={id} className={style[skin]}>
      <BackgroundDivOrImage
        bgClass={style.bg}
        backgroundImageProps={backgroundImage}
      />
      <div className={style.doubleBorderContainer} />
    </div>
  );
};

export default DoubleBorderBgImageStrip;
